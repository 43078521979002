import React, {useMemo} from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import {FaSortDown, FaSortUp} from "react-icons/fa";

export const PolicyTable = ({ searchResult, onPolicySelect }) => {
    const data = useMemo(() => searchResult || [], [searchResult]);
    const columns = useMemo(
        () => [
            {
                Header: "",
                id: "id",
                Cell: ({ row }) => row.index + 1,
                style: {
                    fontWeight: "bold",
                }
            },
            {
                Header: "Name",
                accessor: (row) => `${row.name}`,
                Cell: ({ row }) => {
                    return (
                        <button type="button" className="btn btn-link btn-sm" onClick={() => onPolicySelect(row.original)}>
                            {`${row.original.name}`}
                        </button>
                    );
                },
            },
            {
                Header: "Status",
                accessor: (row) => (row.isActive ? "Active" : "Inactive"),
                Cell: ({ value }) => (
                    <td className={value === "Active" ? "green" : "red"}>
                        {value}
                    </td>
                ),
            },
            {
                Header: "EA",
                accessor: (row) => (row.isEA === "Yes"? "Yes" : "No"),
                Cell: ({ value }) => (
                    <td className={value === "Yes" ? "red" : "green" }>
                        {value}
                    </td>
                ),
            },
            {
                Header: "Employees",
                accessor: "empCount",
                style: {
                    width: "30px",
                    textAlign: "right",
                }
            },
            {
                Header: "",
                id: "buttons",
                accessor: "actions",
                Cell: ({ row }) => (
                    <div className="text-center">
                        <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            onClick={() => {
                                window.open(
                                    "/ops/enroll.policy.record?policy_id=" + row.original.id,
                                    "_blank"
                                );
                            }}
                        >
                            OPS View
                        </button>
                        <button
                            type="button"
                            className="btn btn-outline-primary btn-sm ms-3"
                            onClick={() => {
                                window.open(
                                    "/ops/planadmin.policy.record?policy_id=" + row.original.id,
                                    "_blank"
                                );
                            }}
                        >
                            PA View
                        </button>
                    </div>
                ),
            },
        ], []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        gotoPage,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
        },
        useSortBy,
        usePagination
    );

    return (
        <>
            <div className="pt-2">
                Found <b>{data.length}</b>
            </div>
            <table {...getTableProps()} className="table table-striped">
                <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <span>
                                    {column.isSorted ? (column.isSortedDesc ? <FaSortDown/> : <FaSortUp/>) : ''}
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                                return <td {...cell.getCellProps()} style={cell.column.style}>{cell.render("Cell")}</td>;
                            })}
                        </tr>
                    );
                })}
                </tbody>
            </table>
            <div className="pagination"
                 style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px'}}>
                <div>
                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}
                            className="btn btn-secondary btn-sm">
                        {'<<'}
                    </button>
                    <button onClick={previousPage} disabled={!canPreviousPage} className="btn btn-secondary btn-sm">
                        {'<'}
                    </button>
                    <button onClick={nextPage} disabled={!canNextPage} className="btn btn-secondary btn-sm">
                        {'>'}
                    </button>
                    <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}
                            className="btn btn-secondary btn-sm">
                        {'>>'}
                    </button>
                </div>
                <div>
                    <span>
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{' '}
                    </span>
                    <span>
                        | Go to page:{' '}
                        <input
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={(e) => {
                                const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                                gotoPage(pageNumber);
                            }}
                            style={{width: '50px', marginLeft: '5px'}}
                        />
                    </span>
                </div>
                <div>
                    <select
                        value={pageSize}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                        style={{marginLeft: '10px'}}
                    >
                        {[10, 20, 30, 40].map((size) => (
                            <option key={size} value={size}>
                                Show {size}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </>
    );
}

export const EmployeeTable = ({ searchResult, onEmployeeSelect }) => {
    const data = useMemo(() => searchResult || [], [searchResult]);
    const columns = useMemo(
        () => [
            {
                Header: "",
                id: "id",
                Cell: ({ row }) => row.index + 1,
                style: {
                    fontWeight: "bold",
                }
            },
            {
                Header: "Employee Name",
                accessor: (row) => `${row.lastName}, ${row.firstName}`,
                Cell: ({ row }) => {
                    return (
                        <button type="button" className="btn btn-link btn-sm" onClick={() => onEmployeeSelect(row.original)}>
                            {`${row.original.lastName}, ${row.original.firstName}`}
                        </button>
                    );
                },
            },
            {
                Header: "Status",
                accessor: (row) => (row.isActive ? "Active" : "Inactive"),
                Cell: ({ value }) => (
                    <td className={value === "Active" ? "green" : "red"}>
                        {value}
                    </td>
                ),
            },
            {
                Header: "PA",
                accessor: "isPlanAdmin",
                Cell: ({ value }) => <span>{value ? "Yes" : "No"}</span>,
            },
            {
                Header: "Policy Name",
                accessor: "policyName",
                style: {
                    width: '300px',
                    whiteSpace: 'normal',
                    wordWrap: 'break-word'
                },
            },
            {
                Header: "Latest Claim",
                accessor: "latestClaim",
                Cell: ({ row }) => {
                    const { id, latestClaim } = row.original;
                    return latestClaim ? (
                        <button
                            className="btn-link btn-sm"
                            onClick={() =>
                                window.open(
                                    `https://employee.nationalhealthclaim.com/ee/${id}/viewclaim/${latestClaim.id}`,
                                    "_blank"
                                )
                            }
                        >
                            {latestClaim.claimNo}
                        </button>
                    ) : null;
                },
            },
            {
                Header: "",
                accessor: "buttons",
                id: "actions",
                width: "20%",
                Cell: ({ row }) => (
                    <div className="text-center">
                        <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            onClick={() => {
                                window.open(
                                    "/ops/enroll.policy.record?policy_id=" + row.original.polId,
                                    "_blank"
                                );
                            }}
                        >
                            Policy OPS
                        </button>
                        <button
                            type="button"
                            className="btn btn-outline-primary btn-sm ms-3"
                            onClick={() => {
                                window.open(
                                    "/ops/enroll.employee.record?employee_id=" + row.original.id,
                                    "_blank"
                                );
                            }}
                        >
                            EE Ops
                        </button>
                        <button
                            type="button"
                            className="btn btn-dark btn-sm ms-3"
                            onClick={() => {
                                window.open(
                                    "https://employee.nationalhealthclaim.com/ee/" + row.original.id,
                                    "_blank"
                                );
                            }}
                        >
                            EE Portal
                        </button>
                        <button
                            type="button"
                            className="btn btn-dark btn-sm ms-3"
                            onClick={() => {
                                window.open(
                                    "/ops/planadmin.policy.record?policy_id=" + row.original.polId,
                                    "_blank"
                                );
                            }}
                        >
                            PA View
                        </button>
                    </div>
                ),
            }
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        gotoPage,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
        },
        useSortBy,
        usePagination
    );

    return (
        <>
            <div className="pt-2">
                Found <b>{data.length}</b>
            </div>
            <table {...getTableProps()} className="table table-striped">
                <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} style={column.style}>
                                {column.render('Header')}
                                <span>
                                    {column.isSorted ? (column.isSortedDesc ? <FaSortDown/> : <FaSortUp/>) : ''}
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                            })}
                        </tr>
                    );
                })}
                </tbody>
            </table>
            <div className="pagination"
                 style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px'}}>
                <div>
                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}
                            className="btn btn-secondary btn-sm">
                        {'<<'}
                    </button>
                    <button onClick={previousPage} disabled={!canPreviousPage} className="btn btn-secondary btn-sm">
                        {'<'}
                    </button>
                    <button onClick={nextPage} disabled={!canNextPage} className="btn btn-secondary btn-sm">
                        {'>'}
                    </button>
                    <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}
                            className="btn btn-secondary btn-sm">
                        {'>>'}
                    </button>
                </div>
                <div>
                    <span>
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{' '}
                    </span>
                    <span>
                        | Go to page:{' '}
                        <input
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={(e) => {
                                const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                                gotoPage(pageNumber);
                            }}
                            style={{width: '50px', marginLeft: '5px'}}
                        />
                    </span>
                </div>
                <div>
                    <select
                        value={pageSize}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                        style={{marginLeft: '10px'}}
                    >
                        {[10, 20, 30, 40].map((size) => (
                            <option key={size} value={size}>
                                Show {size}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </>
    );
}

export const ClaimTable = ({ searchResult, onEmployeeSelect }) => {
    const data = useMemo(() => searchResult || [], [searchResult]);
    const columns = useMemo(
        () => [
            {
                Header: "",
                id: "id",
                Cell: ({ row }) => row.index + 1,
                style: {
                    fontWeight: "bold",
                }
            },
            {
                Header: "Claim",
                accessor: (row) => `${row.claimNo}`,
                Cell: ({ row }) => {
                    return (
                        <button
                            onClick={() => {
                                window.open(
                                    `/ops/employee/vieweob?claimId=${row.original.claimId}&employeeId=${row.original.id}`,
                                    "_blank"
                                );
                            }}
                            type="button"
                            className="btn-link btn-sm"
                        >
                            {row.original.claimNo}
                        </button>
                    );
                },
            },
            {
                Header: "Employee Name",
                accessor: (row) => `${row.lastName}, ${row.firstName}`,
                Cell: ({ row }) => {
                    return (
                        <button type="button" className="btn btn-link btn-sm" onClick={() => onEmployeeSelect(row.original)}>
                            {`${row.original.lastName}, ${row.original.firstName}`}
                        </button>
                    );
                },
            },
            {
                Header: "Status",
                accessor: (row) => (row.isActive ? "Active" : "Inactive"),
                Cell: ({ value }) => (
                    <td className={value === "Active" ? "green" : "red"}>
                        {value}
                    </td>
                ),
            },
            {
                Header: "Date of Birth",
                accessor: "dob",
                Cell: ({ value }) => {
                    const date = new Date(value);
                    const formattedDate = date.toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                    });
                    return <span>{formattedDate}</span>;
                }
            },
            {
                Header: "Policy",
                accessor: "policyName"
            },
            {
                Header: "",
                id: "buttons",
                accessor: "actions",
                Cell: ({ row }) => (
                    <div className="text-center">
                        <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            onClick={() => {
                                window.open(
                                    "/ops/enroll.policy.record?policy_id=" + row.original.polId,
                                    "_blank"
                                );
                            }}
                        >
                            OPS View
                        </button>
                        <button
                            type="button"
                            className="btn btn-outline-primary btn-sm ms-3"
                            onClick={() => {
                                window.open(
                                    "/ops/planadmin.policy.record?policy_id=" + row.original.polId,
                                    "_blank"
                                );
                            }}
                        >
                            PA View
                        </button>
                        <button
                            type="button"
                            className="btn btn-dark btn-sm ms-3"
                            onClick={() => {
                                window.open(
                                    `/ops/employee/vieweob?claimId=${row.original.claimId}&employeeId=${row.original.id}`,
                                    "_blank"
                                );
                            }}
                        >
                            View EOB
                        </button>
                    </div>
                ),
            },
        ], []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        gotoPage,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        setPageSize,
        state: {pageIndex, pageSize},
    } = useTable(
        {
            columns,
            data,
        },
        useSortBy,
        usePagination
    );

    return (
        <>
            <div className="pt-2">
                Found <b>{data.length}</b>
            </div>
            <table {...getTableProps()} className="table table-striped">
                <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <span>
                                    {column.isSorted ? (column.isSortedDesc ? <FaSortDown/> : <FaSortUp/>) : ''}
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                            })}
                        </tr>
                    );
                })}
                </tbody>
            </table>
            <div className="pagination"
                 style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px'}}>
                <div>
                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}
                            className="btn btn-secondary btn-sm">
                        {'<<'}
                    </button>
                    <button onClick={previousPage} disabled={!canPreviousPage} className="btn btn-secondary btn-sm">
                        {'<'}
                    </button>
                    <button onClick={nextPage} disabled={!canNextPage} className="btn btn-secondary btn-sm">
                        {'>'}
                    </button>
                    <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}
                            className="btn btn-secondary btn-sm">
                        {'>>'}
                    </button>
                </div>
                <div>
                    <span>
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{' '}
                    </span>
                    <span>
                        | Go to page:{' '}
                        <input
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={(e) => {
                                const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                                gotoPage(pageNumber);
                            }}
                            style={{width: '50px', marginLeft: '5px'}}
                        />
                    </span>
                </div>
                <div>
                    <select
                        value={pageSize}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                        style={{marginLeft: '10px'}}
                    >
                        {[10, 20, 30, 40].map((size) => (
                            <option key={size} value={size}>
                                Show {size}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </>
    );
}

export const DependentTable = ({ searchResult, onEmployeeSelect }) => {
    const data = useMemo(() => searchResult || [], [searchResult]);
    const columns = useMemo(
        () => [
            {
                Header: "",
                id: "id",
                Cell: ({ row }) => row.index + 1,
                style: {
                    fontWeight: "bold",
                }
            },
            {
                Header: "Employee Name",
                accessor: (row) => `${row.lastName}, ${row.firstName}`,
                Cell: ({ row }) => {
                    return (
                        <button type="button" className="btn btn-link btn-sm" onClick={() => onEmployeeSelect(row.original)}>
                            {`${row.original.lastName}, ${row.original.firstName}`}
                        </button>
                    );
                },
            },
            {
                Header: "Status",
                accessor: (row) => (row.isActive ? "Active" : "Inactive"),
                Cell: ({ value }) => (
                    <td className={value === "Active" ? "green" : "red"}>
                        {value}
                    </td>
                ),
            },
            {
                Header: "Date of Birth",
                accessor: "dob",
                Cell: ({ value }) => {
                    const date = new Date(value);
                    const formattedDate = date.toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                    });
                    return <span>{formattedDate}</span>;
                }
            },
            {
                Header: "Policy",
                accessor: "policyName"
            },
            {
                Header: "Latest Claim",
                accessor: "latestClaim",
                Cell: ({ row }) => {
                    const { id, latestClaim } = row.original;
                    return latestClaim ? (
                        <button
                            className="btn-link btn-sm"
                            onClick={() =>
                                window.open(
                                    `https://employee.nationalhealthclaim.com/ee/${id}/viewclaim/${latestClaim.id}`,
                                    "_blank"
                                )
                            }
                        >
                            {latestClaim.claimNo}
                        </button>
                    ) : null;
                },
            },
            {
                Header: "",
                accessor: "buttons",
                id: "actions",
                Cell: ({ row }) => (
                    <div className="text-center">
                        <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            onClick={() => {
                                window.open(
                                    "/ops/enroll.policy.record?policy_id=" + row.original.polId,
                                    "_blank"
                                );
                            }}
                        >
                            OPS View
                        </button>
                        <button
                            type="button"
                            className="btn btn-outline-primary btn-sm ms-3"
                            onClick={() => {
                                window.open(
                                    "/ops/planadmin.employee.record?employee_id=" + data.id,
                                    "_blank"
                                );
                            }}
                        >
                            PA View
                        </button>
                        <button
                            type="button"
                            className="btn btn-dark btn-sm ms-3"
                            onClick={() => {
                                window.open(
                                    "https://employee.nationalhealthclaim.com/ee/" +
                                    data.id,
                                    "_blank"
                                );
                            }}
                        >
                            EE Portal
                        </button>
                    </div>
                ),
            }
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        gotoPage,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        setPageSize,
        state: {pageIndex, pageSize},
    } = useTable(
        {
            columns,
            data,
        },
        useSortBy,
        usePagination
    );

    return (
        <>
            <div className="pt-2">
                Found <b>{data.length}</b>
            </div>
            <table {...getTableProps()} className="table table-striped">
                <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <span>
                                    {column.isSorted ? (column.isSortedDesc ? <FaSortDown/> : <FaSortUp/>) : ''}
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                            })}
                        </tr>
                    );
                })}
                </tbody>
            </table>
            <div className="pagination"
                 style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px'}}>
                <div>
                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}
                            className="btn btn-secondary btn-sm">
                        {'<<'}
                    </button>
                    <button onClick={previousPage} disabled={!canPreviousPage} className="btn btn-secondary btn-sm">
                        {'<'}
                    </button>
                    <button onClick={nextPage} disabled={!canNextPage} className="btn btn-secondary btn-sm">
                        {'>'}
                    </button>
                    <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}
                            className="btn btn-secondary btn-sm">
                        {'>>'}
                    </button>
                </div>
                <div>
                    <span>
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{' '}
                    </span>
                    <span>
                        | Go to page:{' '}
                        <input
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={(e) => {
                                const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                                gotoPage(pageNumber);
                            }}
                            style={{width: '50px', marginLeft: '5px'}}
                        />
                    </span>
                </div>
                <div>
                    <select
                        value={pageSize}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                        style={{marginLeft: '10px'}}
                    >
                        {[10, 20, 30, 40].map((size) => (
                            <option key={size} value={size}>
                                Show {size}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </>
    );
}

export const UserTable = ({ searchResult, onUserSelect }) => {
    const data = useMemo(() => searchResult || [], [searchResult]);
    const columns = useMemo(
        () => [
            {
                Header: "",
                id: "id",
                Cell: ({ row }) => row.index + 1,
            },
            {
                Header: "Username",
                accessor: (row) => `${row.username}`,
                Cell: ({ row }) => {
                    return (
                        <button type="button" className="btn btn-link btn-sm" onClick={() => onUserSelect(row.original)}>
                            {`${row.original.username}`}
                        </button>
                    );
                },
            },
            {
                Header: "Name",
                accessor: (row) => `${row.lastName}, ${row.firstName}`,
                Cell: ({row}) => (
                    <span>
                        {`${row.original.lastName}, ${row.original.firstName}`}
                    </span>
                )
            },
            {
                Header: "Status",
                accessor: (row) => (row.isActive ? "Active" : "Inactive"),
                Cell: ({ value }) => (
                    <td className={value === "Active" ? "green" : "red"}>
                        {value}
                    </td>
                ),
            },
        ], []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        gotoPage,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        setPageSize,
        state: {pageIndex, pageSize},
    } = useTable(
        {
            columns,
            data,
        },
        useSortBy,
        usePagination
    );

    return (
        <>
            <div className="pt-2">
                Found <b>{data.length}</b>
            </div>
            <table {...getTableProps()} className="table table-striped">
                <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <span>
                                    {column.isSorted ? (column.isSortedDesc ? <FaSortDown/> : <FaSortUp/>) : ''}
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                            })}
                        </tr>
                    );
                })}
                </tbody>
            </table>
            <div className="pagination"
                 style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px'}}>
                <div>
                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}
                            className="btn btn-secondary btn-sm">
                        {'<<'}
                    </button>
                    <button onClick={previousPage} disabled={!canPreviousPage} className="btn btn-secondary btn-sm">
                        {'<'}
                    </button>
                    <button onClick={nextPage} disabled={!canNextPage} className="btn btn-secondary btn-sm">
                        {'>'}
                    </button>
                    <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}
                            className="btn btn-secondary btn-sm">
                        {'>>'}
                    </button>
                </div>
                <div>
                    <span>
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{' '}
                    </span>
                    <span>
                        | Go to page:{' '}
                        <input
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={(e) => {
                                const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                                gotoPage(pageNumber);
                            }}
                            style={{width: '50px', marginLeft: '5px'}}
                        />
                    </span>
                </div>
                <div>
                    <select
                        value={pageSize}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                        style={{marginLeft: '10px'}}
                    >
                        {[10, 20, 30, 40].map((size) => (
                            <option key={size} value={size}>
                                Show {size}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </>
    );
}
