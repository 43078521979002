import CustomPagination from "components/CustomPagination";
import React from "react";
import {Button, Spinner} from "react-bootstrap";
import {CSVLink} from "react-csv";
import toastr from "toastr";

export default class OpsAdminSplitReport extends React.Component<any, any> {
    state = {
        loading: true,
        success: false,
        adminFees: [],
        total: 0,
        currentPage: 1,
        itemsPerPage: 10,
        totalNHC: 0,
        totalSalesAB: 0,
        totalSalesON: 0,
        totalCombined: 0,
        revenueData: [],
        filterYear: new Date().getFullYear(),
        filterMonth: new Date().getMonth() + 1,
        filterRecipient: "ALL",
    };


    componentDidMount() {
        this.initialize();
    }

    initialize = () => {
        this.setState({loading: true});
        this.filterByDate();
    };

    setCurrentPage = (page: number) => {
        this.setState({currentPage: page});
    };

    filterByDate = () => {
        this.setState({ loading: true });
        fetch(
            `/api/opstransfer/getadminfeereportbymonth?year=${this.state.filterYear}&month=${this.state.filterMonth}&recipientFilter=${this.state.filterRecipient}`,
            {
                method: "GET",
            }
        )
            .then((response) => response.json())
            .then((data) => {
                this.setState({
                    totalNHC: data.totals.totalNHC || 0,
                    totalSalesAB: data.totals.totalSalesAB || 0,
                    totalSalesON: data.totals.totalSalesON || 0,
                    totalCombined: data.totals.totalCombined || 0,
                    revenueData: data.data,
                    loading: false,
                    success: true,
                });
            })
            .catch((error) => {
                this.setState({
                    totalNHC: 0,
                    totalSalesAB: 0,
                    totalSalesON: 0,
                    totalCombined: 0,
                    revenueData: [],
                    loading: false,
                    success: false,
                });
                toastr.error("Failed to load data", "Error");
            });
    };
    
    render() {
        const indexOfLastRecord = this.state.currentPage * this.state.itemsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - this.state.itemsPerPage;
        const currentRecords = this.state.revenueData.slice(
            indexOfFirstRecord,
            indexOfLastRecord
        );
        const nPages = Math.ceil(
            this.state.revenueData.length / this.state.itemsPerPage
        );

        let totalRevenue = this.state.totalCombined;

        var adminFeeTable = null;
        if (this.state.revenueData.length > 0) {
            adminFeeTable = (
                <>
                    <table className="table">
                        <thead>
                        <tr>
                            {/* <th></th> */}
                            <th style={{minWidth: 100}}>Trans Date</th>
                            <th style={{minWidth: 100}}>Enroll Date</th>
                            <th>Policy</th>
                            <th>Claim No.</th>
                            <th className="text-end">Total Approved</th>
                            <th className="text-end">Policy Active Months</th>
                            <th>Recipient</th>
                            <th>Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        {currentRecords.map((data, i) => {
                            return (
                                <tr>
                                    <td>{data.transDate}</td>
                                    <td>{data.createdAt}</td>
                                    <td>{data.name}</td>
                                    <td>{data.claimNo}</td>
                                    <td className="text-end">{data.totalApproved.toFixed(2)}</td>
                                    <td className="text-end">{data.policyActiveMonths}</td>
                                    <td>{data.recipientType}</td>
                                    <td className="text-end">{data.transAmount.toFixed(2)}</td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                    <div
                        style={{
                            position: "absolute",
                            left: "25px",
                            margin: "0px",
                            fontWeight: 500,
                            fontSize: 20,
                        }}
                    >
                        Total Revenue:{" "}
                        {this.state.totalCombined.toLocaleString(undefined, {
                            style: "currency",
                            currency: "USD",
                        })}
                    </div>
                    <div
                        style={{
                            position: "absolute",
                            left: "25px",
                            margin: "0px",
                            paddingTop: 60,
                            fontWeight: 500,
                            fontSize: 20,
                        }}
                    >
                        Total NHC Revenue:{" "}
                        {this.state.totalNHC.toLocaleString(undefined, {
                            style: "currency",
                            currency: "USD",
                        })}
                    </div>
                    <div
                        style={{
                            position: "absolute",
                            left: "25px",
                            margin: "0px",
                            paddingTop: 90,
                            fontWeight: 500,
                            fontSize: 20,
                        }}
                    >
                        Total SALES (AB) Revenue:{" "}
                        {this.state.totalSalesAB.toLocaleString(undefined, {
                            style: "currency",
                            currency: "USD",
                        })}
                    </div>
                    <div
                        style={{
                            position: "absolute",
                            left: "25px",
                            margin: "0px",
                            paddingTop: 120,
                            fontWeight: 500,
                            fontSize: 20,
                        }}
                    >
                        Total SALES (ON) Revenue:{" "}
                        {this.state.totalSalesON.toLocaleString(undefined, {
                            style: "currency",
                            currency: "USD",
                        })}
                    </div>
                    <div
                        className="pagination-container"
                        style={{
                            position: "relative",
                            right: "10px",
                            margin: "0px",
                            justifyContent: "end",
                            height: 100,
                        }}
                    >
                        <div className="pagination-info">
              <span>
                Showing {indexOfFirstRecord + 1} -{" "}
                  {indexOfFirstRecord + currentRecords.length} entries out of{" "}
                  {this.state.revenueData.length} entries
              </span>
                        </div>
                        <CustomPagination
                            nPages={nPages}
                            currentPage={this.state.currentPage}
                            setCurrentPage={this.setCurrentPage}
                        />
                    </div>
                </>
            );
        }

        const today = new Date().toISOString().slice(0, 10);
        const fileName = `adminfees_${today}.csv`;

        return (
            <>
                {this.state.loading ? (
                    <div className="financial-spinner">
                        <Spinner animation="border"/>
                    </div>
                ) : (
                    <div style={{overflowX: "auto"}}>
                        <div className="mb-2 d-flex justify-content-between">
                            <div>
                                <div className="mb-2 d-flex flex-start">
                                    {/* Month Dropdown */}
                                    <select
                                        value={this.state.filterMonth}
                                        onChange={(e) => this.setState({filterMonth: parseInt(e.target.value)})}
                                        className="form-select mx-2"
                                        aria-label="Select Month"
                                    >
                                        {Array.from({length: 12}, (_, i) => (
                                            <option key={i + 1} value={i + 1}>
                                                {new Date(0, i).toLocaleString("default", {month: "long"})}
                                            </option>
                                        ))}
                                    </select>

                                    {/* Year Dropdown */}
                                    <select
                                        value={this.state.filterYear}
                                        onChange={(e) => this.setState({filterYear: parseInt(e.target.value)})}
                                        className="form-select mx-2"
                                        aria-label="Select Year"
                                    >
                                        {Array.from({length: new Date().getFullYear() - 2020 + 1}, (_, i) => (
                                            <option key={new Date().getFullYear() - i}
                                                    value={new Date().getFullYear() - i}>
                                                {new Date().getFullYear() - i}
                                            </option>
                                        ))}
                                    </select>
                                    <select
                                        value={this.state.filterRecipient}
                                        onChange={(e) => this.setState({filterRecipient: e.target.value})}
                                        className="form-select mx-2"
                                    >
                                        <option value="ALL">Show All</option>
                                        <option value="NHC">Show NHC Only</option>
                                        <option value="SALES">Show Sales Only</option>
                                    </select>
                                    <button
                                        onClick={this.filterByDate}
                                        type="button"
                                        className="btn btn-primary btn-sm mt-2 mx-2"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>

                            {this.state.revenueData.length > 0 ? (
                                <div>
                                    <CSVLink data={this.state.revenueData} filename={fileName}>
                                        <Button
                                            variant="primary"
                                            className="csv-button export-to-csv"
                                        >
                                            <span className="csv-text">Export to CSV</span>
                                        </Button>
                                    </CSVLink>
                                </div>
                            ) : null}
                        </div>

                        {adminFeeTable}
                    </div>
                )}
            </>
        );
    }
}
