import React from "react";
import {KTMScreen} from "ktm-ui";
import Accordion from "react-bootstrap/Accordion";
import OpsTransferReport from "./Reports/OpsTransferReport";
import OpsTaxReport from "./Reports/OpsTaxesReport";
import OpsAdminFeeReport from "./Reports/OpsAdminFeeReport";
import OpsAdminSplitReport from "./Reports/OpsAdminSplitReport";

export default KTMScreen(
    {name: "operationalreports"},
    class extends React.Component<any, any> {
        render() {
            return (
                <div>
                    <div className="ktm-financials-content">
                        <div style={{maxWidth: 1200, minWidth: 1200}}>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        Admin Fee Report
                                    </Accordion.Header>
                                    <Accordion.Body style={{minHeight: 250}}>
                                        <OpsAdminSplitReport/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        Policy Admin Fee Report
                                    </Accordion.Header>
                                    <Accordion.Body style={{minHeight: 250}}>
                                        <OpsAdminFeeReport/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                {/*<Accordion.Item eventKey="1">*/}
                                {/*  <Accordion.Header>Distro Ledger</Accordion.Header>*/}
                                {/*  <Accordion.Body style={{minHeight:250}}>*/}
                                {/*    <OpsLedgerReport />*/}
                                {/*  </Accordion.Body>*/}
                                {/*</Accordion.Item>*/}
                                {/*<Accordion.Item eventKey="2">*/}
                                {/*  <Accordion.Header>*/}
                                {/*    Claim Disbursement Summary (by Province)*/}
                                {/*  </Accordion.Header>*/}
                                {/*  <Accordion.Body style={{minHeight:250}}>*/}
                                {/*    <OpsDisbursementSummary />*/}
                                {/*  </Accordion.Body>*/}
                                {/*</Accordion.Item>*/}
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Operational Transfers</Accordion.Header>
                                    <Accordion.Body style={{minHeight: 250}}>
                                        <OpsTransferReport/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>
                                        Taxes (Claim Disbursements)
                                    </Accordion.Header>
                                    <Accordion.Body style={{minHeight: 250}}>
                                        <OpsTaxReport/>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>
            );
        }
    }
);
