import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,Button,Display,Line,Card,Column,Panel} from 'ktm-ui';
import CustomToolTip from 'components/CustomToolTip';

interface IdsDependent {
    Id:string;
    Employee_Id:string;
    Policy_Id:string;
    FirstName:string;
    LastName:string;
    Email:string;
    MaidenName:string;
    Nickname:string;
    DateOfBirth:string;
    Relation:string;
    IsHidden:boolean;
};

export default KTMScreen({name:"planadmin.employee.edit-dependent"}, class extends React.Component<{dependent_id:string}> {

    data:{
        dsDependent:IdsDependent;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsDependent";
        await KTMApi.start(screen,{
            "@dependent_id":this.props.dependent_id,
        });
        screen.title = "Edit Dependent";
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout dialogSize="medium">
                {draw(this.header)}
                {draw(this.main_tab)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <Dialog.Header label="Edit Dependent"/>
        );
    }

    footer({field,draw,action}:RenderEvent){
        let dependentSubmitButton = null;
        if((this.data.dsDependent.FirstName === undefined || this.data.dsDependent.FirstName === '') || (this.data.dsDependent.LastName === undefined || this.data.dsDependent.LastName === '') || (this.data.dsDependent.Relation === undefined || this.data.dsDependent.Relation === '-- Select --') || (this.data.dsDependent.DateOfBirth === undefined || this.data.dsDependent.DateOfBirth === '')) {
            dependentSubmitButton = 
            <button disabled className='btn btn-secondary'>Save</button>
        }
        else {
            dependentSubmitButton = <Button action={action(this.submit)} label="Save" size="large" style="primary"/>
        }

        return (
            <Dialog.Footer>
                {dependentSubmitButton}
            </Dialog.Footer>
        );
    }

    details({field,draw,action}:RenderEvent){
        return (
            <Card>
                <Line>
                    <Display required field={field("FirstName")}/>
                    <Display required field={field("LastName")}/>
                    <Display field={field("MaidenName")}/>
                    <Display field={field("Nickname")}/>
                </Line>
                <Line>
                    <Display required field={field("Relation")}/>
                    <Display required field={field("DateOfBirth")}/>
                </Line>
                <Display 
                    field={field("IsHidden")}
                    toolTip={
                        <CustomToolTip
                          header="Is Hidden"
                          bodyContent="If checked, this dependent is no longer visible to the employee. This is effective immediately, but benefit coverage will update accordingly at the start of the next benefit year. The dependent entry must be kept for record keeping purposes, but only plan administrators and NHC can see a dependent who has been hidden."
                        />
                      }
                />
            </Card>
        );
    }

    main_tab({field,draw,action}:RenderEvent){
        return (
            <Panel>
                <Column>
                    {draw(this.details)}
                </Column>
            </Panel>
        );
    }

    async submit({screen}:ActionEvent){
        const employeeLogs = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                EmployeeId : this.props.dependent_id,
                FirstName:this.data.dsDependent.FirstName,
                LastName:this.data.dsDependent.LastName,
                Email:this.data.dsDependent.Email,
                MaidenName:this.data.dsDependent.MaidenName,
                Nickname:this.data.dsDependent.Nickname,
                DateOfBirth:this.data.dsDependent.DateOfBirth,
                Relation:this.data.dsDependent.Relation,
                IsHidden:this.data.dsDependent.IsHidden,
            }),
        };
        await KTMApi.action(screen,"submit",{
            "@dsDependent.FirstName":this.data.dsDependent.FirstName,
            "@dsDependent.LastName":this.data.dsDependent.LastName,
            "@dsDependent.Email":this.data.dsDependent.Email,
            "@dsDependent.MaidenName":this.data.dsDependent.MaidenName,
            "@dsDependent.Nickname":this.data.dsDependent.Nickname,
            "@dsDependent.DateOfBirth":this.data.dsDependent.DateOfBirth,
            "@dsDependent.Relation":this.data.dsDependent.Relation,
            "@dsDependent.IsHidden":this.data.dsDependent.IsHidden,
            "@dependent_id":this.props.dependent_id,
        }).then((data) => {
            fetch("/api/PlanAdmin/updatedependentlogs", employeeLogs);
       });
        screen.close({"continue":true});
    }

});
