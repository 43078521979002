import CustomPagination from "components/CustomPagination";
import {Button, Spinner} from "react-bootstrap";
import React from "react";
import {CSVLink} from "react-csv";
import {CustomDateRangePicker} from "components/CustomDateRangePicker";

export default class OpsTaxReport extends React.Component<any, any> {
    state = {
        loading: true,
        success: false,
        opsTaxes: [],
        total: 0,
        currentPage: 1,
        itemsPerPage: 10,
        fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        toDate: new Date(),
    };

    componentDidMount() {
        this.initialize();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.fromDate !== this.state.fromDate ||
            prevState.toDate !== this.state.toDate
        ) {
            this.setState({loading: true});
            this.initialize();
        }
    }

    setCurrentPage = (page: number) => {
        this.setState({currentPage: page});
    };

    initialize = () => {
        fetch(
            "/api/opstransfer/getopstaxreport?dateFrom=" +
            this.state.fromDate.toISOString() +
            "&dateTo=" +
            this.state.toDate.toISOString(),
            {
                method: "GET",
            }
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    this.setState({
                        loading: false,
                        opsTaxes: data.data || [],
                        total: data.total || 0,
                    });
                } else {
                    this.setState({
                        loading: false,
                        opsTaxes: [],
                        total: 0,
                    });
                }
            })
            .catch((error) => {
                this.setState({loading: false});
            });
    };

    setFromDate = (date) => {
        this.setState({
            fromDate: date,
        });
    };

    setToDate = (date) => {
        this.setState({
            toDate: date,
        });
    };

    render() {
        const indexOfLastRecord = this.state.currentPage * this.state.itemsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - this.state.itemsPerPage;
        const currentRecords = this.state.opsTaxes.slice(
            indexOfFirstRecord,
            indexOfLastRecord
        );
        const nPages = Math.ceil(
            this.state.opsTaxes.length / this.state.itemsPerPage
        );

        let opsTaxesTable = null;
        if (this.state.opsTaxes.length > 0) {
            opsTaxesTable = (
                <>
                    <table className="table">
                        <thead>
                        <tr>
                            <th>Trans Type</th>
                            <th className="text-end">Total Trans Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        {currentRecords.map((item, i) => (
                            <tr key={i}>
                                <td>{item.transType}</td>
                                <td className="text-end">
                                    {item.totalTransAmount.toLocaleString(undefined, {
                                        style: "currency",
                                        currency: "USD",
                                    })}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                    {/* Pagination */}
                    <div
                        className="pagination-container"
                        style={{
                            position: "relative",
                            right: "10px",
                            margin: "0px",
                            justifyContent: "end",
                        }}
                    >
                        <div className="pagination-info">
              <span>
                Showing {indexOfFirstRecord + 1} -{" "}
                  {indexOfFirstRecord + currentRecords.length} entries out of{" "}
                  {this.state.opsTaxes.length} entries
              </span>
                        </div>
                        <CustomPagination
                            nPages={nPages}
                            currentPage={this.state.currentPage}
                            setCurrentPage={this.setCurrentPage}
                        />
                    </div>
                </>
            );
        }

        const today = new Date().toISOString().slice(0, 10);
        const fileName = `opstaxes_${today}.csv`;

        return (
            <>
                {this.state.loading ? (
                    <div className="financial-spinner">
                        <Spinner animation="border"/>
                    </div>
                ) : (
                    <div>
                        <div className="mb-2 d-flex justify-content-between">
                            <CustomDateRangePicker
                                fromDate={this.state.fromDate}
                                toDate={this.state.toDate}
                                setFromDate={this.setFromDate}
                                setToDate={this.setToDate}
                            />
                            {this.state.opsTaxes.length > 0 ? (
                                <div>
                                    {/* Export to CSV */}
                                    <CSVLink data={this.state.opsTaxes} filename={fileName}>
                                        <Button variant="primary" className="csv-button export-to-csv">
                                            <span className="csv-text">Export to CSV</span>
                                        </Button>
                                    </CSVLink>
                                </div>
                            ) : null}
                        </div>

                        {/* Table */}
                        {opsTaxesTable}
                    </div>
                )}
            </>
        );
    }
}
