import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,Button,Display,Line,Card,Column,Panel} from 'ktm-ui';
import toastr from "toastr";
import CustomIntegerInput from 'components/CustomIntegerInput';

interface IdsBenCat {
    Id: string;
    Policy_Id: string;
    CreditCalcMethod: string;
    AllowRSPOption: boolean;
    AllowStockOption: boolean;
    HasHSA: boolean;
    HasLSA: boolean;
    HSALabel: string;
    LSALabel: string;
    RSPLabel: string;
    Stocklabel: string;
    DefaultRspAllocation: number;
    DefaultStockAllocation: number;
    DefaultHSAAllocation: number;
    DefaultLSAAllocation: number;
    AllowLSADependentClaims: boolean;
    HSACap: number;
    LSACap: number;
    RSPCap: number;
    StockCap: number;
    AllowTFSAOption: boolean;
    TFSALabel: string;
    DefaultTFSAAllocation: number;
    TFSACap: number;
}

type State = {
    showCampaignWarning: boolean;
    hsaCap: number;
    lsaCap: number;
    rspCap: number;
    inputPrefix: string;
    tfsaCap: number;
};

export default KTMScreen({name:"planadmin.ben-limit.edit-flex-settings"}, class extends React.Component<{limit_id:string, employeesCount:number}, State> {

    data: {
        dsBenCat: IdsBenCat;
    };

    constructor(props: { limit_id:string, employeesCount:number }) {
        super(props);
        this.state = {
            showCampaignWarning: false,
            hsaCap: 0,
            lsaCap: 0,
            rspCap: 0,
            tfsaCap: 0,
            inputPrefix: "$"
        };
    }

    render() {
        return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
        screen.defaultRecord = "@dsBenCat";
        await KTMApi.start(screen, {
            "@limit_id": this.props.limit_id,
        });
        screen.title = "Edit Flex Settings";
        this.updateInputPrefix(screen.getValue("@dsBenCat.CreditCalcMethod"))
        await this.checkCampaignStatus();
    }

    updateInputPrefix(creditCalcMethod: string) {
        this.setState({inputPrefix : creditCalcMethod === "SALARY" ? "%" : "$"})
    }

    handleHsaCapInputChange = (value: number) => {
        this.setState({ hsaCap: value });
        this.data.dsBenCat.HSACap = value;
    };

    handleLsaCapInputChange = (value: number) => {
        this.setState({ lsaCap: value });
        this.data.dsBenCat.LSACap = value;
    };

    handleRspCapInputChange = (value: number) => {
        this.setState({ rspCap: value });
        this.data.dsBenCat.RSPCap = value;
    };

    handleTfsaCapInputChange = (value: number) => {
        this.setState({ tfsaCap: value });
        this.data.dsBenCat.TFSACap = value;
    };

    main({ field, draw, action }: RenderEvent) {
        return (
            <Layout>
                {draw(this.header)}
                {draw(this.main_tab)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({ field, draw, action }: RenderEvent) {
        return (
            <Dialog.Header label="Edit Flex Settings"/>
        );
    }

    footer({ field, draw, action }: RenderEvent) {
        return (
            <Dialog.Footer>
                <Button action={action(this.submit)} label="Save" size="large" style="primary" />
            </Dialog.Footer>
        );
    }

    hsa({ field, draw, action }: RenderEvent) {
        if (!this.data.dsBenCat.HasHSA) {
            this.data.dsBenCat.HSALabel = "";
            this.data.dsBenCat.DefaultHSAAllocation = 0;
        }
        return (
            <Card label="HSA">
                <Display field={field("HasHSA")}/>
                <Line>
                    <Display field={field("HSALabel")} disabled={!this.data.dsBenCat.HasHSA}/>
                    <Display field={field("DefaultHSAAllocation")} disabled={!this.data.dsBenCat.HasHSA}/>
                </Line>
                {(this.data.dsBenCat.DefaultHSAAllocation === 0 || this.data.dsBenCat.CreditCalcMethod !== "SALARY") && (
                <Line>
                    <CustomIntegerInput
                        label="HSA Cap"
                        value={this.data.dsBenCat.HSACap}
                        onChange={this.handleHsaCapInputChange}
                        icon={this.state.inputPrefix}
                    />
                </Line>
                )}
            </Card>
        );
    }

    lsa({ field, draw, action }: RenderEvent) {
        if (!this.data.dsBenCat.HasLSA) {
            this.data.dsBenCat.AllowLSADependentClaims = false;
            this.data.dsBenCat.LSALabel = "";
            this.data.dsBenCat.DefaultLSAAllocation = 0;
        }
        return (
            <Card label="LSA">
                <Line>
                    <Display required field={field("HasLSA")} />
                    <Display required field={field("AllowLSADependentClaims")} disabled={!this.data.dsBenCat.HasLSA} />
                </Line>
                <Line>
                    <Display field={field("LSALabel")} disabled={!this.data.dsBenCat.HasLSA}/>
                    <Display field={field("DefaultLSAAllocation")} disabled={!this.data.dsBenCat.HasLSA}/>
                </Line>
                {(this.data.dsBenCat.DefaultLSAAllocation === 0 || this.data.dsBenCat.CreditCalcMethod !== "SALARY") && (
                <Line>
                    <CustomIntegerInput
                        label="LSA Cap"
                        value={this.data.dsBenCat.LSACap}
                        onChange={this.handleLsaCapInputChange}
                        icon={this.state.inputPrefix}
                    />
                </Line>
                )}
            </Card>
        );
    }

    rsp({ field, draw, action }: RenderEvent) {
        if (!this.data.dsBenCat.AllowRSPOption) {
            this.data.dsBenCat.RSPLabel = "";
            this.data.dsBenCat.DefaultRspAllocation = 0;
        }
        return (
            <Card label="RSP">
                <Display field={field("AllowRSPOption")}/>
                <Line>
                    <Display field={field("RSPLabel")} disabled={!this.data.dsBenCat.AllowRSPOption}/>
                    <Display field={field("DefaultRspAllocation")} disabled={!this.data.dsBenCat.AllowRSPOption}/>
                </Line>
                {(this.data.dsBenCat.DefaultRspAllocation === 0 || this.data.dsBenCat.CreditCalcMethod !== "SALARY") && (
                <Line>
                    <CustomIntegerInput
                        label="RSP Cap"
                        value={this.data.dsBenCat.RSPCap}
                        onChange={this.handleRspCapInputChange}
                        icon={this.state.inputPrefix}
                    />
                </Line>
                )}
            </Card>
        );
    }

    stock({field,draw,action}:RenderEvent){
        return (
            <Card label="Stock">
                <Display field={field("AllowStockOption")}/>
                <Line>
                    <Display field={field("Stocklabel")}/>
                    <Display field={field("DefaultStockAllocation")}/>
                </Line>
            </Card>
        );
    }

    tfsa({ field, draw, action }: RenderEvent) {
        if (!this.data.dsBenCat.AllowTFSAOption) {
            this.data.dsBenCat.TFSALabel = "";
            this.data.dsBenCat.DefaultTFSAAllocation = 0;
        }
        return (
            <Card label="TFSA">
                <Display field={field("AllowTFSAOption")} />
                <Line>
                    <Display field={field("DefaultTFSAAllocation")} disabled={!this.data.dsBenCat.AllowTFSAOption} />
                    <Display field={field("TFSALabel")} disabled={!this.data.dsBenCat.AllowTFSAOption} />
                </Line>
                {(this.data.dsBenCat.DefaultTFSAAllocation === 0 || this.data.dsBenCat.CreditCalcMethod !== "SALARY") && (
                <Line>
                    <CustomIntegerInput
                        label="TFSA Cap"
                        value={this.data.dsBenCat.TFSACap}
                        onChange={this.handleTfsaCapInputChange}
                        icon={this.state.inputPrefix}
                    />
                </Line>
                )}
            </Card>
        );
    }

    main_tab({field,draw,action}:RenderEvent){
        return (
            <Panel>
                <Column>
                    {(this.state.showCampaignWarning && this.props.employeesCount > 0) && (
                        <Card>
                            <Line>
                                <span style={{ color: "red", fontSize: 14, fontWeight: 'bold' }}>
                                    This benefit category has been assigned to employees as part of their year-end Flex Campaign. Any changes saved here will apply to the next benefit year as well as to the current one, and the assigned employees may be required to re-select.
                                    <br />
                                    If assistance is required, please click ‘Cancel’ and contact enrollments@nationalhealthclaim.com.
                                </span>
                            </Line>
                        </Card>
                    )}
                    {draw(this.hsa)}
                    {draw(this.lsa)}
                    {draw(this.rsp)}
                    {draw(this.tfsa)}
                    {/* {draw(this.stock)} */}
                </Column>
            </Panel>
        );
    }

    async submit({ screen }: ActionEvent) {
        this.data.dsBenCat.DefaultHSAAllocation = Math.round(this.data.dsBenCat.DefaultHSAAllocation);
        this.data.dsBenCat.DefaultLSAAllocation = Math.round(this.data.dsBenCat.DefaultLSAAllocation);
        this.data.dsBenCat.DefaultRspAllocation = Math.round(this.data.dsBenCat.DefaultRspAllocation);
        this.data.dsBenCat.DefaultTFSAAllocation = Math.round(this.data.dsBenCat.DefaultTFSAAllocation);

        let totalAllocation = this.data.dsBenCat.DefaultHSAAllocation + 
                              this.data.dsBenCat.DefaultLSAAllocation + 
                              this.data.dsBenCat.DefaultRspAllocation +
                              this.data.dsBenCat.DefaultTFSAAllocation;

        if (totalAllocation > 100) {
            toastr.error("Total allocation cannot exceed 100%.");
            return;
        } else if (totalAllocation < 100) {
            toastr.error("Total allocation must equal 100%.");
            return;
        }

        if(this.data.dsBenCat.CreditCalcMethod === "SALARY")
        {
            if (this.data.dsBenCat.DefaultHSAAllocation > 0)
                this.data.dsBenCat.HSACap = 0;
            if (this.data.dsBenCat.DefaultLSAAllocation > 0)
                this.data.dsBenCat.LSACap = 0;
            if (this.data.dsBenCat.DefaultRspAllocation > 0)
                this.data.dsBenCat.RSPCap = 0;
            if (this.data.dsBenCat.DefaultTFSAAllocation > 0)
                this.data.dsBenCat.TFSACap = 0;
        }

        await KTMApi.action(screen, "submit", this.data.dsBenCat);
        screen.close({"continue":true});
    }

    async checkCampaignStatus() {
        let response = await fetch("/api/Utility/checkcampaignstatus?policyId=" + this.data.dsBenCat.Policy_Id);
        let res = await response.json();
        if (res.isCampaignOpen) {
            this.setState({ showCampaignWarning: true });
        }
    }
});
