import React from "react";
import {KTMScreen} from "ktm-ui";
import toastr from "toastr";
import {Modal, Spinner,} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/free-solid-svg-icons";
import ReactButton from "react-bootstrap/Button";

export default KTMScreen(
    {name: "operationaltransfer"},
    class extends React.Component<any, any> {
        state = {
            loading: true,
            success: false,
            pendingAdminFee: 0,
            pendingNhcPortion: 0,
            pendingSalesPortion: 0,
            pendingGst: 0,
            pendingOnRst: 0,
            pendingOnPremium: 0,
            submitAdminFee: 0,
            submitGst: 0,
            submitOnRst: 0,
            submitOnPremium: 0,

            // Billing
            pendingTeladocRevenue: 0,
            pendingTeladocGST: 0,
            pendingCorehealthRevenue: 0,
            submitTeladocRevenue: 0,
            submitTeladocGST: 0,
            submitCorehealthRevenue: 0,
            
            showConfirm: false,
            formType: "",
            formAmount: 0,
            confirmValue: null,
            filterYear: new Date().getFullYear(),
            filterMonth: new Date().getMonth() + 1,
            submissionDate: new Date(),
        };

        componentDidMount() {
            this.initialize();
        }

        initialize = () => {
            this.setState({loading: true});
            this.filterByDate();
        };

        filterByDate = () => {
            this.setState({loading: true});
            fetch(
                "/api/opstransfer/getpendingtransfersbymonth?year=" +
                this.state.filterYear +
                "&month=" +
                this.state.filterMonth,
                {
                    method: "GET",
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    this.setState({
                        pendingAdminFee: data.totalAmount || 0,
                        pendingNhcPortion: data.totalNHC || 0,
                        pendingSalesPortion: (data.totalSalesAB || 0) + (data.totalSalesON || 0),
                        pendingGst: data.totalGSTHST || 0,
                        pendingOnRst: data.totalOnRst || 0,
                        pendingOnPremium: data.totalOnPremium || 0,
                        submitAdminFee: data.totalAmount || 0,
                        submitGst: data.totalGSTHST || 0,
                        submitOnRst: data.totalOnRst || 0,
                        submitOnPremium: data.totalOnPremium || 0,
                        pendingTeladocRevenue: data.nhcTeladoc || 0,
                        pendingTeladocGST: data.taxnhcTeladoc || 0,
                        pendingCorehealthRevenue: data.nhcCorehealth || 0,
                        submitTeladocRevenue: data.nhcTeladoc || 0,
                        submitTeladocGST: data.taxnhcTeladoc || 0,
                        submitCorehealthRevenue: data.nhcCorehealth || 0,
                        loading: false,
                        success: true,
                    });
                })
                .catch((error) => {
                    this.setState({
                        pendingAdminFee: 0,
                        pendingNhcPortion: 0,
                        pendingSalesPortion: 0,
                        pendingGst: 0,
                        pendingOnRst: 0,
                        pendingOnPremium: 0,
                        submitAdminFee: 0,
                        submitGst: 0,
                        submitOnRst: 0,
                        submitOnPremium: 0,
                        pendingTeladocRevenue: 0,
                        pendingTeladocGST: 0,
                        pendingCorehealthRevenue: 0,
                        submitTeladocRevenue: 0,
                        submitTeladocGST: 0,
                        submitCorehealthRevenue: 0,
                        loading: false,
                        success: false,
                    });
                    toastr.error("Failed to load data", "Error");
                });
        };

        setInput = (event) => {
            var val = event.target.value;
            val = val.replace(/^0+(?=\d)/, "");
            val = val.replace(/[^0-9.]/g, "");
            val = val.replace(/(\..*)\./g, "$1");
            this.setState({
                [event.target.name]: val,
            });
        };

        copyClipboard = (val) => {
            navigator.clipboard
                .writeText(val)
                .then(() => {
                    toastr.success("Copied to clipboard", "Success");
                })
                .catch((error) => {
                    toastr.error("Failed to copy", "Error");
                });
        };

        confirmAmount = (type, amount) => {
            let description = "Confirm";
            if (type == "admin") {
                description = "NHC Revenue";
            } else if (type == "gst") {
                description = "GST/HST";
            } else if (type == "rst") {
                description = "Ontario RST";
            } else if (type == "premium") {
                description = "Ontario Premium Tax";
            }else if (type === "teladocRev") {
                description = "Teladoc Revenue";
            } else if (type === "teladocGST") {
                description = "Teladoc GST";
            } else if (type === "corehealthRev") {
                description = "Corehealth Revenue";
            }
            let confirmText =
                description +
                ": " + new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                }).format(amount).replace(/^(\D+)/, '$');
            this.setState({
                showConfirm: true,
                formType: type,
                formAmount: amount,
                confirmValue: confirmText,
            });
        };
        submitAmount = (type,amount) => {
            this.setState({
                loading: true,
                showConfirm: false,
            });

            fetch("/api/opstransfer/submittransferbymonth", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    Type: type,
                    Amount: amount,
                    Year: this.state.filterYear,
                    Month: this.state.filterMonth,
                    PendingNhcPortion: this.state.pendingNhcPortion,
                    PendingSalesPortion: this.state.pendingSalesPortion,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    this.setState(
                        {
                            success: data.success,
                        },
                        () => {
                            if (!this.state.success) {
                                toastr.error("Failed to submit", "Error");
                            } else {
                                toastr.success("Transfer submitted", "Success");
                                this.initialize();
                            }
                        }
                    );
                })
                .catch((error) => {
                    toastr.error("Submission failed", "Error");  
                    this.setState({
                        loading: false,
                        showConfirm: false,
                    });
                });
        };

        render() {
            let confirmModal = null;
            confirmModal = (
                <Modal
                    className="react-modal react-modal-confirm"
                    show={this.state.showConfirm}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header className="react-modal-title">
                        <Modal.Title>Confirm Submission</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            Are you sure you want to submit this operational transfer?
                        </div>
                        {this.state.confirmValue != null ? (
                            <div style={{color: "green", marginTop: 15, fontSize: 20}}>
                                {this.state.confirmValue}
                            </div>
                        ) : null}
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton
                            variant="light"
                            onClick={() => this.setState({showConfirm: false})}
                        >
                            Cancel
                        </ReactButton>
                        <ReactButton
                            variant="primary"
                            onClick={() =>
                                this.submitAmount(this.state.formType, this.state.formAmount)
                            }
                        >
                            Submit
                        </ReactButton>
                    </Modal.Footer>
                </Modal>
            );

            return (
                <div>
                    {confirmModal}
                    <div className="ktm-financials-content">
                        {this.state.loading ? (
                            <div className="financial-spinner">
                                <Spinner animation="border"/>
                            </div>
                        ) : (
                            <>
                                <div className="ktm-financials-label">
                                    Transfers to Operational Account
                                </div>
                                <div>
                                    <div className="container mt-2">
                                        <div className="row">
                                            <div className="col my-2">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div>
                                                            <div className="form-check">
                                                                <label className="form-check-label">
                                                                    Select by month
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="mb-2 d-flex flex-start">
                                                                {/* Month Dropdown */}
                                                                <select
                                                                    value={this.state.filterMonth}
                                                                    onChange={(e) => this.setState({filterMonth: parseInt(e.target.value)})}
                                                                    className="form-select mx-2"
                                                                    aria-label="Select Month"
                                                                >
                                                                    {Array.from({length: 12}, (_, i) => (
                                                                        <option key={i + 1} value={i + 1}>
                                                                            {new Date(0, i).toLocaleString("default", {month: "long"})}
                                                                        </option>
                                                                    ))}
                                                                </select>

                                                                {/* Year Dropdown */}
                                                                <select
                                                                    value={this.state.filterYear}
                                                                    onChange={(e) => this.setState({filterYear: parseInt(e.target.value)})}
                                                                    className="form-select mx-2"
                                                                    aria-label="Select Year"
                                                                >
                                                                    {Array.from({length: new Date().getFullYear() - 2020 + 1}, (_, i) => (
                                                                        <option key={new Date().getFullYear() - i}
                                                                                value={new Date().getFullYear() - i}>
                                                                            {new Date().getFullYear() - i}
                                                                        </option>
                                                                    ))}
                                                                </select>

                                                                <button
                                                                    onClick={this.filterByDate}
                                                                    type="button"
                                                                    className="btn btn-primary btn-sm mt-2 mx-2"
                                                                >
                                                                    Submit
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col my-2">
                                            <div className="card">
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <b>NHC Revenue</b>
                                                            </div>
                                                            <div className="ml-auto">
            <span
                style={{
                    color:
                        this.state.pendingAdminFee > 0
                            ? "red"
                            : "green",
                }}
            >
            <b>
                Pending{" "}
                {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                }).format(this.state.pendingAdminFee).replace(/^(\D+)/, '$')}
            </b>
            </span>
                                                                <button
                                                                    onClick={() =>
                                                                        this.copyClipboard(
                                                                            this.state.pendingAdminFee
                                                                        )
                                                                    }
                                                                    className="btn btn-sm no-focus-shadow"
                                                                >
                                                                    <FontAwesomeIcon icon={faCopy}/>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="input-group mt-2">
                                                                <input
                                                                    // disabled={this.state.pendingAdminFee == 0}
                                                                    disabled={true}
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="submitAdminFee"
                                                                    onChange={(e) => this.setInput(e)}
                                                                    value={this.state.submitAdminFee}
                                                                    placeholder={"Enter pending amount"}
                                                                />
                                                                <button
                                                                    disabled={
                                                                        this.state.pendingAdminFee == 0 ||
                                                                        this.state.submitAdminFee == 0 ||
                                                                        this.state.submitAdminFee >
                                                                        this.state.pendingAdminFee
                                                                    }
                                                                    onClick={() =>
                                                                        this.confirmAmount(
                                                                            "admin",
                                                                            this.state.submitAdminFee
                                                                        )
                                                                    }
                                                                    className="btn btn-outline-primary"
                                                                    type="button"
                                                                    style={{zIndex: 0}}
                                                                >
                                                                    Submit
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col my-2">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <b>GST/HST Amount</b>
                                                            </div>
                                                            <div className="ml-auto">
            <span
                style={{
                    color:
                        this.state.pendingGst > 0
                            ? "red"
                            : "green",
                }}
            >
            <b>
                Pending{" "}
                {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                }).format(this.state.pendingGst).replace(/^(\D+)/, '$')}
            </b>
            </span>
                                                                <button
                                                                    onClick={() =>
                                                                        this.copyClipboard(this.state.pendingGst)
                                                                    }
                                                                    className="btn btn-sm no-focus-shadow"
                                                                >
                                                                    <FontAwesomeIcon icon={faCopy}/>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="input-group mt-2">
                                                                <input
                                                                    // disabled={this.state.pendingGst == 0}
                                                                    disabled={true}
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="submitGst"
                                                                    onChange={(e) => this.setInput(e)}
                                                                    value={this.state.submitGst}
                                                                    placeholder={"Enter pending amount"}
                                                                />
                                                                <button
                                                                    disabled={
                                                                        this.state.pendingGst == 0 ||
                                                                        this.state.submitGst == 0 ||
                                                                        this.state.submitGst > this.state.pendingGst
                                                                    }
                                                                    onClick={() =>
                                                                        this.confirmAmount(
                                                                            "gst",
                                                                            this.state.submitGst
                                                                        )
                                                                    }
                                                                    className="btn btn-outline-primary"
                                                                    type="button"
                                                                    style={{zIndex: 0}}
                                                                >
                                                                    Submit
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col my-2">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <b>Ontario RST Amount</b>
                                                            </div>
                                                            <div className="ml-auto">
            <span
                style={{
                    color:
                        this.state.pendingOnRst > 0
                            ? "red"
                            : "green",
                }}
            >
            <b>
                Pending{" "}
                {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                }).format(this.state.pendingOnRst).replace(/^(\D+)/, '$')}
            </b>
            </span>
                                                                <button
                                                                    onClick={() =>
                                                                        this.copyClipboard(this.state.pendingOnRst)
                                                                    }
                                                                    className="btn btn-sm no-focus-shadow"
                                                                >
                                                                    <FontAwesomeIcon icon={faCopy}/>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="input-group mt-2">
                                                                <input
                                                                    // disabled={this.state.pendingOnRst == 0}
                                                                    disabled={true}
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="submitOnRst"
                                                                    onChange={(e) => this.setInput(e)}
                                                                    value={this.state.submitOnRst}
                                                                    placeholder={"Enter pending amount"}
                                                                />
                                                                <button
                                                                    disabled={
                                                                        this.state.pendingOnRst == 0 ||
                                                                        this.state.submitOnRst == 0 ||
                                                                        this.state.submitOnRst >
                                                                        this.state.pendingOnRst
                                                                    }
                                                                    onClick={() =>
                                                                        this.confirmAmount(
                                                                            "rst",
                                                                            this.state.submitOnRst
                                                                        )
                                                                    }
                                                                    className="btn btn-outline-primary"
                                                                    type="button"
                                                                    style={{zIndex: 0}}
                                                                >
                                                                    Submit
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col my-2">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <b>Ontario Premium Tax Amount</b>
                                                            </div>
                                                            <div className="ml-auto">
            <span
                style={{
                    color:
                        this.state.pendingOnPremium > 0
                            ? "red"
                            : "green",
                }}
            >
            <b>
                Pending{" "}
                {this.state.pendingOnPremium.toLocaleString(
                    undefined,
                    {style: "currency", currency: "USD"}
                )}
            </b>
            </span>
                                                                <button
                                                                    onClick={() =>
                                                                        this.copyClipboard(
                                                                            this.state.pendingOnPremium
                                                                        )
                                                                    }
                                                                    className="btn btn-sm no-focus-shadow"
                                                                >
                                                                    <FontAwesomeIcon icon={faCopy}/>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="input-group mt-2">
                                                                <input
                                                                    // disabled={this.state.pendingOnPremium == 0}
                                                                    disabled={true}
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="submitOnPremium"
                                                                    onChange={(e) => this.setInput(e)}
                                                                    value={this.state.submitOnPremium}
                                                                    placeholder={"Enter pending amount"}
                                                                />
                                                                <button
                                                                    disabled={
                                                                        this.state.pendingOnPremium == 0 ||
                                                                        this.state.submitOnPremium == 0 ||
                                                                        this.state.submitOnPremium >
                                                                        this.state.pendingOnPremium
                                                                    }
                                                                    onClick={() =>
                                                                        this.confirmAmount(
                                                                            "premium",
                                                                            this.state.submitOnPremium
                                                                        )
                                                                    }
                                                                    className="btn btn-outline-primary"
                                                                    type="button"
                                                                    style={{zIndex: 0}}
                                                                >
                                                                    Submit
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            {/* Teladoc Revenue */}
                                            <div className="col my-2">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <b>Teladoc Revenue</b>
                                                            </div>
                                                            <div className="ml-auto">
                                <span
                                    style={{
                                        color:
                                            this.state.pendingTeladocRevenue > 0
                                                ? "red"
                                                : "green",
                                    }}
                                >
                                  <b>
                                    Pending{" "}
                                      {new Intl.NumberFormat("en-US", {
                                          style: "currency",
                                          currency: "USD",
                                      })
                                          .format(this.state.pendingTeladocRevenue)
                                          .replace(/^(\D+)/, "$")}
                                  </b>
                                </span>
                                                                <button
                                                                    onClick={() =>
                                                                        this.copyClipboard(
                                                                            this.state.pendingTeladocRevenue
                                                                        )
                                                                    }
                                                                    className="btn btn-sm no-focus-shadow"
                                                                >
                                                                    <FontAwesomeIcon icon={faCopy} />
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="input-group mt-2">
                                                                <input
                                                                    disabled={true}
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="submitTeladocRevenue"
                                                                    onChange={(e) => this.setInput(e)}
                                                                    value={this.state.submitTeladocRevenue}
                                                                    placeholder={"Enter pending amount"}
                                                                />
                                                                <button
                                                                    disabled={
                                                                        this.state.pendingTeladocRevenue === 0 ||
                                                                        this.state.submitTeladocRevenue === 0 ||
                                                                        this.state.submitTeladocRevenue >
                                                                        this.state.pendingTeladocRevenue
                                                                    }
                                                                    onClick={() =>
                                                                        this.confirmAmount(
                                                                            "teladocRev",
                                                                            this.state.submitTeladocRevenue
                                                                        )
                                                                    }
                                                                    className="btn btn-outline-primary"
                                                                    type="button"
                                                                    style={{ zIndex: 0 }}
                                                                >
                                                                    Submit
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Teladoc GST */}
                                            <div className="col my-2">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <b>Teladoc GST</b>
                                                            </div>
                                                            <div className="ml-auto">
                                <span
                                    style={{
                                        color:
                                            this.state.pendingTeladocGST > 0
                                                ? "red"
                                                : "green",
                                    }}
                                >
                                  <b>
                                    Pending{" "}
                                      {new Intl.NumberFormat("en-US", {
                                          style: "currency",
                                          currency: "USD",
                                      })
                                          .format(this.state.pendingTeladocGST)
                                          .replace(/^(\D+)/, "$")}
                                  </b>
                                </span>
                                                                <button
                                                                    onClick={() =>
                                                                        this.copyClipboard(
                                                                            this.state.pendingTeladocGST
                                                                        )
                                                                    }
                                                                    className="btn btn-sm no-focus-shadow"
                                                                >
                                                                    <FontAwesomeIcon icon={faCopy} />
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="input-group mt-2">
                                                                <input
                                                                    disabled={true}
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="submitTeladocGST"
                                                                    onChange={(e) => this.setInput(e)}
                                                                    value={this.state.submitTeladocGST}
                                                                    placeholder={"Enter pending amount"}
                                                                />
                                                                <button
                                                                    disabled={
                                                                        this.state.pendingTeladocGST === 0 ||
                                                                        this.state.submitTeladocGST === 0 ||
                                                                        this.state.submitTeladocGST >
                                                                        this.state.pendingTeladocGST
                                                                    }
                                                                    onClick={() =>
                                                                        this.confirmAmount(
                                                                            "teladocGST",
                                                                            this.state.submitTeladocGST
                                                                        )
                                                                    }
                                                                    className="btn btn-outline-primary"
                                                                    type="button"
                                                                    style={{ zIndex: 0 }}
                                                                >
                                                                    Submit
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            {/* Corehealth Revenue */}
                                            <div className="col my-2">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <b>Corehealth Revenue</b>
                                                            </div>
                                                            <div className="ml-auto">
                                <span
                                    style={{
                                        color:
                                            this.state.pendingCorehealthRevenue > 0
                                                ? "red"
                                                : "green",
                                    }}
                                >
                                  <b>
                                    Pending{" "}
                                      {new Intl.NumberFormat("en-US", {
                                          style: "currency",
                                          currency: "USD",
                                      })
                                          .format(this.state.pendingCorehealthRevenue)
                                          .replace(/^(\D+)/, "$")}
                                  </b>
                                </span>
                                                                <button
                                                                    onClick={() =>
                                                                        this.copyClipboard(
                                                                            this.state.pendingCorehealthRevenue
                                                                        )
                                                                    }
                                                                    className="btn btn-sm no-focus-shadow"
                                                                >
                                                                    <FontAwesomeIcon icon={faCopy} />
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="input-group mt-2">
                                                                <input
                                                                    disabled={true}
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="submitCorehealthRevenue"
                                                                    onChange={(e) => this.setInput(e)}
                                                                    value={this.state.submitCorehealthRevenue}
                                                                    placeholder={"Enter pending amount"}
                                                                />
                                                                <button
                                                                    disabled={
                                                                        this.state.pendingCorehealthRevenue === 0 ||
                                                                        this.state.submitCorehealthRevenue === 0 ||
                                                                        this.state.submitCorehealthRevenue >
                                                                        this.state.pendingCorehealthRevenue
                                                                    }
                                                                    onClick={() =>
                                                                        this.confirmAmount(
                                                                            "corehealthRev",
                                                                            this.state.submitCorehealthRevenue
                                                                        )
                                                                    }
                                                                    className="btn btn-outline-primary"
                                                                    type="button"
                                                                    style={{ zIndex: 0 }}
                                                                >
                                                                    Submit
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        <div className="col"></div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            );
        }
    }
);
