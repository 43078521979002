import React, { useState, useEffect, useMemo, useCallback } from "react";
import axios from "axios";
import { Button, Table } from "react-bootstrap";
import { CSVLink } from "react-csv";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import { CustomLoading } from "components/CustomLoading";
import { CustomDateRangePicker } from "components/CustomDateRangePicker";
import CustomPagination from "components/CustomPagination";
import CustomToolTip from "components/CustomToolTip";
import { EmptyStateTemplate } from "./Empty_state_Template";
import "../../styles/Reports.css";
import base64 from "../../data/base64.json";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const LifestyleTaxableReportTable: React.FC<{ policyId: string }> = ({ policyId }) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<any[]>([]);
    const [dataToExport, setDataToExport] = useState<any[]>([]);
    const [totals, setTotals] = useState({ taxableTotal: 0, nonTaxableTotal: 0, combinedTotal: 0 });
    const [fromDate, setFromDate] = useState(() => new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [toDate, setToDate] = useState(() => new Date());
    const [currentPage, setCurrentPage] = useState(1);
    const [showBenefitType, setShowBenefitType] = useState(false);
    const itemsPerPage = 12;

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
        const { data: response } = await axios.get(
            `/api/Reports/lifestyletaxablereport`,
            {
            params: {
                policyId,
                fromDate: fromDate.toISOString(),
                toDate: toDate.toISOString(),
            },
            }
        );
        if (response.success) {
            setData(response.result);
            setTotals({
                taxableTotal: response.taxableTotal,
                nonTaxableTotal: response.nonTaxableTotal,
                combinedTotal: response.combinedTotal,
            });
            if(response.nonTaxableTotal > 0)
            {
                setShowBenefitType(true);
            }

            const processedData = processData(response.result, response.combinedTotal, response.taxableTotal, response.nonTaxableTotal);
            setDataToExport(processedData.dataToExport);
        } else {
            setData([]);
            setTotals({ taxableTotal: 0, nonTaxableTotal: 0, combinedTotal: 0 });
            setDataToExport([]);
        }
        } catch (error) {
        console.error("Error fetching report data", error);
        } finally {
        setLoading(false);
        }
    }, [policyId, fromDate, toDate]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const processData = (data, combinedTotal, taxableTotal, nonTaxableTotal) => {

        const formattedData = data.map(({ lastName, firstName, employeeNo, benefitType, paidTotal }) => {
            const row = [lastName, firstName];
            if (nonTaxableTotal > 0) {
                row.push(benefitType);
            }
            row.push(employeeNo, paidTotal);

            return row;
        });
    
        const headerRow = ["Last Name", "First Name"];
        if (nonTaxableTotal > 0) {
            headerRow.push("Benefit Type");
        }
        headerRow.push("Employee No", "Paid Total");
    
        const footerRows = [];
    
        if (nonTaxableTotal > 0) {
            footerRows.push(["Taxable Total", "", "", "", taxableTotal.toFixed(2)]);
            footerRows.push(["Non Taxable Total", "", "", "", nonTaxableTotal.toFixed(2)]);
            footerRows.push(["Combined Total", "", "", "", combinedTotal.toFixed(2)]);
        }
        else
        {
            footerRows.push(["Total", "", "", combinedTotal.toFixed(2)]);
        }

        const dateRangeRow = ["Date Range", `${fromDate.toISOString().slice(0, 10)} - ${toDate.toISOString().slice(0, 10)}`];
    
        return {
            dataToExport: [dateRangeRow, headerRow, ...formattedData, ...footerRows],
        };
    };

    const exportToPdf = () => {
        const header = dataToExport[1];
        const bodyRows = dataToExport.slice(2, dataToExport.length - (showBenefitType ? 3 : 1));
        const footerRows = dataToExport.slice(dataToExport.length - (showBenefitType ? 3 : 1));
    
        const headerData = formatRow(header, 'tableHeader');
        const bodyData = formatTableBody(bodyRows);
        footerRows.forEach(footerRow => {
            bodyData.push(formatRow(footerRow, 'tableFooter'));
        });
    
        const docDefinition = {
            pageSize: 'A4',
            pageOrientation: "landscape",
            content: [
                {
                    columns: [
                        createLogo(),
                        createDateRange(dataToExport[0][1]),
                    ],
                },
                createTitle(),
                createTable(headerData, bodyData),
            ],
            styles: getPdfStyles(),
            layout: getTableLayout(),
        };
    
        pdfMake.createPdf(docDefinition).download("Lifestyle_Taxable_Report.pdf");
    };
        
    const createLogo = () => ({
        image: base64.logo,
        width: 230,
    });

    const createTitle = () => ({
        text: 'Lifestyle Taxable Report',
        fontSize: 16,
        bold: true,
        alignment: 'center',
        margin: [10, 40, 0, 10]
    });

    const createDateRange = (dateRange) => ({
        text: `Date Range: ${dateRange}`,
        fontSize: 12,
        bold: true,
        alignment: 'right',
        margin: [0, 0, 0, 8]
    });

    const formatRow = (rowData, style) => {
        return rowData.map(cell => {
            const isNumeric = !isNaN(parseFloat(cell)) && isFinite(cell);
            const isPercentage = typeof cell === 'string' && cell.includes('%');

            return {
                text: String(cell || ''),
                style,
                alignment: (isNumeric || isPercentage) ? 'right' : 'left'
            };
        });
    };

    const formatTableBody = (rows) => rows.map(row => formatRow(row, 'tableBody'));

    const createTable = (header, body) => ({
        style: 'table',
        table: {
            widths: Array(header.length).fill('*'),
            body: [header, ...body]
        },
        margin: [59.528, 0, 59.528, 0]
    });

    const getPdfStyles = () => ({
        table: {
            margin: [0, 5, 0, 15]
        },
        tableHeader: {
            bold: true,
            fontSize: 12,
            color: 'black'
        },
        tableBody: {
            fontSize: 10,
            color: 'black',
            padding: [2, 2, 2, 2]
        },
        tableFooter: {
            bold: true,
            fontSize: 10,
            color: 'black'
        }
    });

    const getTableLayout = () => ({
        hLineWidth: (i, node) => 0.5,
        vLineWidth: (i, node) => 0.5,
        paddingLeft: (i, node) => 4,
        paddingRight: (i, node) => 4
    });

    const indexOfLastRecord = currentPage * itemsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - itemsPerPage;
    const currentRecords = useMemo(() => data.slice(indexOfFirstRecord, indexOfLastRecord), [data, indexOfFirstRecord, indexOfLastRecord]);

    return (
        <div className="lifestyle-taxable-report-Table report-table-container">
        <div className="report-toolbar" style={{ display: "flex", alignItems: "center" }}>
            <CustomDateRangePicker fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
            {data.length > 0 && (
            <div className="export-button-container">
                <Button variant="primary" className="export-to-pdf" onClick={exportToPdf}>
                    Export to PDF
                </Button>
                <CSVLink data={dataToExport} filename="Lifestyle_Taxable_Report.csv" className="export-button">
                <Button variant="primary" className="csv-button export-to-csv">
                    Export to CSV
                </Button>
                </CSVLink>
            </div>
            )}
        </div>

        {loading ? (
            <CustomLoading />
        ) : data.length > 0 ? (
            <>
            <Table hover className="report-table">
                <thead>
                <tr>
                    <th className="table-text-content">Last Name</th>
                    <th className="table-text-content">First Name</th>
                    {showBenefitType && 
                    <th className="table-text-content">
                        Benefit Type
                        <CustomToolTip header="Benefit Type" bodyContent="All taxable LSA and taxable Enhanced Benefits have been combined. Enhanced Benefits that have been marked as Non Taxable will appear separately." />
                    </th>}
                    <th>Employee No</th>
                    <th>
                        Paid Total
                        <CustomToolTip header="Paid Total" bodyContent="Claim amount excluding taxes and admin fees." />
                    </th>
                </tr>
                </thead>
                <tbody>
                {currentRecords.map(({ lastName, firstName, employeeNo, benefitType, paidTotal }, index) => (
                    <tr key={index} className="table-body-itme">
                    <td className="table-text-content">{lastName}</td>
                    <td className="table-text-content">{firstName}</td>
                    {showBenefitType && <td className="table-text-content">{benefitType}</td>}
                    <td>{employeeNo}</td>
                    <td>{paidTotal}</td>
                    </tr>
                ))}
                </tbody>
                <tfoot>
                    {showBenefitType ? (
                        <>
                            <tr>
                                <td colSpan={4} className="table-footer-item total-amount">Taxable Total</td>
                                <td className="table-footer-item">{totals.taxableTotal.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td colSpan={4} className="table-footer-item total-amount">Non Taxable Total</td>
                                <td className="table-footer-item">{totals.nonTaxableTotal.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td colSpan={4} className="table-footer-item total-amount">Combined Total</td>
                                <td className="table-footer-item">{totals.combinedTotal.toFixed(2)}</td>
                            </tr>
                        </>
                    ) : (
                        <tr>
                            <td colSpan={showBenefitType ? 4 : 3} className="table-footer-item total-amount">Total</td>
                            <td className="table-footer-item">{totals.combinedTotal.toFixed(2)}</td>
                        </tr>
                    )}
                </tfoot>
            </Table>
            <div className="pagination-container">
                <div className="pagination-info">
                Showing {indexOfFirstRecord + 1} - {indexOfFirstRecord + currentRecords.length} of {data.length}
                </div>
                <CustomPagination nPages={Math.ceil(data.length / itemsPerPage)} currentPage={currentPage} setCurrentPage={setCurrentPage} />
            </div>
            </>
        ) : (
            <EmptyStateTemplate />
        )}
        </div>
    );
};

export default LifestyleTaxableReportTable;
